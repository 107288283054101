<template>
  <div>
    <TableFilter
      :filter="filter"
      :labels="{ company: $t('assigned_to'), product: $tc('product_type') }"
    ></TableFilter>

    <b-table
      ref="table"
      :current-page="page"
      :data="devices"
      :loading="loading"
      :total="total"
      backend-pagination
      class="is-clickable mt-2"
      hoverable
      paginated
      pagination-simple
      pagination-size="is-small"
      per-page="30"
      @click="
        (e) =>
          this.$router.push({
            name: 'DeviceView',
            params: { id: e.id },
          })
      "
      @page-change="(e) => (page = e)"
    >
      <template #empty>
        {{ loading ? $t("loading") : $t("no_matching_results") }}
      </template>

      <b-table-column field="serialNumber" :label="$t('serial_number')">
        <template v-slot="props">
          {{ props.row.serialNumber }}
        </template>
      </b-table-column>

      <b-table-column field="product.name" :label="$tc('product_type')">
        <template v-slot="props">
          {{ props.row.product.name }}
        </template>
      </b-table-column>

      <b-table-column
        v-if="userHasRoleSuperAdmin"
        field="assignedTo.name"
        :label="$t('assigned_to')"
      >
        <template v-slot="props">
          <span v-if="props.row.assignedTo">
            {{ props.row.assignedTo.name }}
          </span>
        </template>
      </b-table-column>

      <b-table-column
        v-if="userHasRoleCompanyAdmin || userHasRoleSuperAdmin"
        field="assignedTo.name"
        :label="$t('contract_active')"
      >
        <template v-slot="props">
          <span v-if="props.row.activeContract">
            {{ props.row.activeContract.company.name }}
            ({{ props.row.activeContract.startDate | date }} &ndash;
            {{ props.row.activeContract.endDate | date }})
          </span>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableFilter from "../../../components/TableFilter";

export default {
  components: { TableFilter },
  data() {
    return {
      loading: true,
      page: 1,
      total: null,
      filter: {
        search: null,
        product: null,
        company: null,
      },
      devices: [],
    };
  },
  computed: {
    ...mapGetters(["userHasRoleCompanyAdmin", "userHasRoleSuperAdmin"]),
  },
  watch: {
    page() {
      this.load();
    },
    filter: {
      deep: true,
      handler() {
        this.load();
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getDevices"]),
    load() {
      this.loading = true;

      this.getDevices({
        page: this.page,
        serialNumber: this.filter.search,
        product: this.filter.product,
        assignedTo: this.filter.company,
      }).then(async ({ items, total }) => {
        this.devices = items;
        this.total = total;

        this.loading = false;
      });
    },
  },
};
</script>
