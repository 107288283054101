import store from "../store";

export default class CompanyCache {
  companies = [];

  async get(id) {
    let company = this.companies.find((c) => c.id === id);

    if (!company) {
      company = await store.dispatch("getCompany", id);
      this.companies.push(company);
    }

    return company;
  }
}
