<template>
  <div>
    <div class="page-header">
      <h1>
        {{ $tc("device", 2) }}
      </h1>
      <b-button
        v-if="userHasRoleSuperAdmin"
        type="is-primary"
        icon-left="plus"
        tag="router-link"
        :to="{ name: 'DeviceCreate' }"
      >
        {{ $t("device_create") }}
      </b-button>
      <b-button
        v-if="userHasRoleSuperAdmin"
        type="is-primary"
        icon-left="plus"
        tag="router-link"
        :to="{ name: 'AccessoryCreate' }"
      >
        {{ $t("accessory_create") }}
      </b-button>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <b-tabs>
          <b-tab-item :label="$tc('product', 2)">
            <Devices></Devices>
          </b-tab-item>

          <b-tab-item :label="$tc('accessory', 2)">
            <Accessories :company-cache="companyCache"></Accessories>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyCache from "../../helpers/CompanyCache";
import Accessories from "./partials/Accessories";
import Devices from "./partials/Devices";

export default {
  name: "DeviceIndex",
  components: { Accessories, Devices },
  data() {
    return {
      companyCache: new CompanyCache(),
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
  },
};
</script>
