var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TableFilter',{attrs:{"filter":_vm.filter,"labels":{
      company: _vm.$t('assigned_to'),
      productType: _vm.$tc('accessory_type'),
    }}}),_c('b-table',{ref:"table",staticClass:"is-clickable mt-2",attrs:{"current-page":_vm.page,"data":_vm.devices,"loading":_vm.loading,"total":_vm.total,"backend-pagination":"","hoverable":"","paginated":"","pagination-simple":"","pagination-size":"is-small","per-page":"30"},on:{"click":(e) =>
        this.$router.push({
          name: 'AccessoryView',
          params: { id: e.id },
        }),"page-change":(e) => (_vm.page = e)},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.loading ? _vm.$t("loading") : _vm.$t("no_matching_results"))+" ")]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"name","label":_vm.$t('name')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"productType.name","label":_vm.$tc('accessory_type')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.productType.name)+" ")]}}])}),(_vm.userHasRoleSuperAdmin)?_c('b-table-column',{attrs:{"field":"assignedTo.name","label":_vm.$t('assigned_to')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.assignedTo)?_c('span',[_vm._v(" "+_vm._s(props.row.assignedTo.name)+" ")]):_vm._e()]}}],null,false,1813775604)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }