<template>
  <div>
    <TableFilter
      :filter="filter"
      :labels="{
        company: $t('assigned_to'),
        productType: $tc('accessory_type'),
      }"
    ></TableFilter>

    <b-table
      ref="table"
      :current-page="page"
      :data="devices"
      :loading="loading"
      :total="total"
      backend-pagination
      class="is-clickable mt-2"
      hoverable
      paginated
      pagination-simple
      pagination-size="is-small"
      per-page="30"
      @click="
        (e) =>
          this.$router.push({
            name: 'AccessoryView',
            params: { id: e.id },
          })
      "
      @page-change="(e) => (page = e)"
    >
      <template #empty>
        {{ loading ? $t("loading") : $t("no_matching_results") }}
      </template>

      <b-table-column field="name" :label="$t('name')">
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>

      <b-table-column field="productType.name" :label="$tc('accessory_type')">
        <template v-slot="props">
          {{ props.row.productType.name }}
        </template>
      </b-table-column>

      <b-table-column
        v-if="userHasRoleSuperAdmin"
        field="assignedTo.name"
        :label="$t('assigned_to')"
      >
        <template v-slot="props">
          <span v-if="props.row.assignedTo">
            {{ props.row.assignedTo.name }}
          </span>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableFilter from "../../../components/TableFilter";
import API from "../../../helpers/API";

export default {
  components: { TableFilter },
  props: {
    companyCache: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      page: 1,
      total: null,
      filter: {
        search: null,
        productType: null,
        company: null,
      },
      devices: [],
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
  },
  watch: {
    page() {
      this.load();
    },
    filter: {
      deep: true,
      handler() {
        this.load();
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAccessories", "getAllProductTypes"]),
    load() {
      this.loading = true;

      this.getAllProductTypes().then((productTypes) => {
        this.getAccessories({
          page: this.page,
          name: this.filter.search,
          productType: this.filter.productType,
          assignedTo: this.filter.company,
        }).then(async ({ items, total }) => {
          for (const i of items) {
            i.productType = productTypes.find(
              (p) => p["@id"] === i.productType
            );

            if (!this.userHasRoleSuperAdmin) {
              continue;
            }

            if (i.assignedTo) {
              i.assignedTo = await this.companyCache.get(
                API.stripId(i.assignedTo)
              );
            }
          }
          this.devices = items;
          this.total = total;

          this.loading = false;
        });
      });
    },
  },
};
</script>
